<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-6">
        <canvas id="nav-chart"></canvas>
      </div>
      <div class="col-lg-12 col-md-6">
        <template>
          <div class="text-center mt-4">
            <b-button class="btn-product px-4" @click="buttonChartNav(1)"
              >1D</b-button
            >
            <b-button class="btn-product px-4" @click="buttonChartNav(2)"
              >1M</b-button
            >
            <b-button class="btn-product px-4" @click="buttonChartNav(3)"
              >3M</b-button
            >
            <!-- <b-button class="btn-product px-4" @click="buttonChartNav(4)">YTD</b-button> -->
            <b-button
              class="btn-product px-4"
              @click="buttonChartNav(5)"
              :class="{ active: isActive }"
              >1Y</b-button
            >
            <b-button class="btn-product px-4" @click="buttonChartNav(6)"
              >3Y</b-button
            >
            <b-button class="btn-product px-4" @click="buttonChartNav(7)"
              >5Y</b-button
            >
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import navChartData from "./nav-data";
import navChartDataOneDay from "./nav-data-one-day";
import navChartDataOneMonth from "./nav-data-one-month";
import navChartDataThreeMonth from "./nav-data-three-month";
import navChartDataYtd from "./nav-data-ytd";
import navChartDataThreeYear from "./nav-data-three-year";
import navChartDataFiveYear from "./nav-data-five-year";

export default {
  name: "NavPerformanceChart",
  props: {
    idProduct: {
      type: Number,
    },
  },
  data() {
    return {
      isActive: true,
      navChartData: navChartData(this.idProduct),
      navChartDataOneDay: navChartDataOneDay(this.idProduct),
      navChartDataOneMonth: navChartDataOneMonth(this.idProduct),
      navChartDataThreeMonth: navChartDataThreeMonth(this.idProduct),
      navChartDataYtd: navChartDataYtd(this.idProduct),
      navChartDataThreeYear: navChartDataThreeYear(this.idProduct),
      navChartDataFiveYear: navChartDataFiveYear(this.idProduct),
    };
  },
  async mounted() {
    let ctx5 = document.getElementById("nav-chart");
    new Chart(ctx5, await this.navChartData);
  },
  methods: {
    async buttonChartNav(id) {
      if (id == 1) {
        const ctx1 = document.getElementById("nav-chart");
        new Chart(ctx1, await this.navChartDataOneDay);
        this.isActive = false;
      } else if (id == 2) {
        let ctx2 = document.getElementById("nav-chart");
        new Chart(ctx2, await this.navChartDataOneMonth);
        this.isActive = false;
      } else if (id == 3) {
        let ctx3 = document.getElementById("nav-chart");
        new Chart(ctx3, await this.navChartDataThreeMonth);
        this.isActive = false;
      } else if (id == 4) {
        let ctx4 = document.getElementById("nav-chart");
        new Chart(ctx4, await this.navChartDataYtd);
        this.isActive = false;
      } else if (id == 5) {
        let ctx5 = document.getElementById("nav-chart");
        new Chart(ctx5, await this.navChartData);
        this.isActive = false;
      } else if (id == 6) {
        let ctx6 = document.getElementById("nav-chart");
        new Chart(ctx6, await this.navChartDataThreeYear);
        this.isActive = false;
      } else if (id == 7) {
        let ctx7 = document.getElementById("nav-chart");
        new Chart(ctx7, await this.navChartDataFiveYear);
        this.isActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
    margin-left: 15px;
  }

  .btn-product {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    color: #6b7280;
    background: #f3f4f6;
    color: #4b5563;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid #d1d5db;
  }

  .btn-product:hover {
    // background: #e60012;
    background: #111827;
    color: white;
  }

  .btn-product:focus {
    // background: #e60012 !important;
    background: #111827;
    color: white !important;
    border: 1px solid #d1d5db !important;
    box-shadow: none !important;
  }

  .btn-product:active {
    // background: #e60012 !important;
    background: #111827;
    color: white !important;
    border: 1px solid #d1d5db !important;
    box-shadow: none !important;
  }

  .active {
    // background: #e60012 !important;
    background: #111827;
    color: white !important;
    border: 1px solid #d1d5db !important;
    box-shadow: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .btn-product {
    font-size: 9px !important;
    padding: 10px 16px !important;
  }
}
</style>
