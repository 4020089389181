import axios from "axios";
import moment from "moment";

const navChartDataOneDay = async (id) => {
    let arr_one_day = [];
    let response = await axios({
        method: "GET",
        url: `nav-history/one-day?id=${id}`,
    });
    const data = response.data;
    data.forEach((d) => {
        const date = moment(d.nav_date, "YYYYMMDD").format('LL');

        arr_one_day.push({ date, nav_per_unit: d.nav_per_unit });
    });

    let dates = arr_one_day.map(d => d.date).reverse();
    let nav_per_unit = arr_one_day.map(d => d.nav_per_unit).reverse();


    const result = {
        type: "line",
        data: {
            labels: dates,
            datasets: [
                {
                    label: "Performa NAV 1 Hari",
                    data: nav_per_unit, //isi nya nav /per unit
                    // backgroundColor: "rgba(238, 80, 63, 0.2)",
                    // borderColor: "#EB321E",
                    backgroundColor: "rgba(11, 49, 143, 0.2)",
                    borderColor: "#0B318F",
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 6
                },
            ]
        },
        options: {
            elements: {
                point: {
                    radius: 0
                }
            },
            responsive: true,
            lineTension: 1,
            
            scales: {
                
                yAxes: [
                    {
            ticks: {
                autoSkip: true,
                  maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0
            },
                    }
                ],
                xAxes: [{
                    offset: true,
                    type: 'time',
                    time: {
                        unit: 'day'
                    },
                    gridLines: {
                        display: false
                    }
                }]
            }
        }
    }
    return result
};



export default navChartDataOneDay;
