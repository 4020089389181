import axios from "axios";

const aumChartData = async (id) => {
    let arr_five_year = [];

    let response = await axios({
        method: "GET",
        url: `aum-history/five-year?id=${id}`,
    });
    const data = response.data;
    data.forEach((d) => {

        d.aum = d.aum / 1000000000;
        arr_five_year.push({ date: d.aum_date, aum: d.aum });
    });

    let dates = arr_five_year.map(d => d.date).reverse();
    let aum = arr_five_year.map(d => d.aum).reverse();

    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
    const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

    const result = { 
        type: "line",
        data: {
            labels: dates,
            datasets: [
                {
                    label: "Dana Kelolaan dalam 5 Tahun",
                    data: aum, //isi nya nav /per unit
                    // backgroundColor: "rgba(11, 49, 143, 0.2)",
                    // borderColor: "#0B318F",
                    backgroundColor: "rgba(238, 80, 63, 0.2)",
                    borderColor: "#EB321E",
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 6,
                    segment: {
                        borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
                        borderDash: ctx => skipped(ctx, [6, 6]),
                    }
                },
            ]
        },
        options: {
            elements: {
                point: {
                    radius: 0
                }
            },
            responsive: true,
            lineTension: 1,
            scales: {
                yAxes: [
                    {
            ticks: {
                autoSkip: true,
                  maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0
            },
                    }
                ],
                xAxes: [{
                        ticks: {
                autoSkip: true,
                  maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0
            },
          type: 'time',
          time: {

            tooltipFormat: 'LL'
          },

          gridLines: {
            display: false
          }
        }]
            }
        }
    }
    return result
};



export default aumChartData;