import axios from "axios";

const navChartDataThreeYear = async (id) => {
    let arr_three_year = [];

    let response = await axios({
        method: "GET",
        url: `nav-history/three-year?id=${id}`,

    });
    const data = response.data;
    data.forEach((d) => {


        arr_three_year.push({ date: d.nav_date, nav_per_unit: d.nav_per_unit });
    });

    let dates = arr_three_year.map(d => d.date).reverse();
    let nav_per_unit = arr_three_year.map(d => d.nav_per_unit).reverse();

    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
    const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

    const result = {
        type: "line",
        data: {
            labels: dates,
            datasets: [
                {
                    label: "Performa NAV 3 Tahun",
                    data: nav_per_unit, //isi nya nav /per unit
                    // backgroundColor: "rgba(238, 80, 63, 0.2)",
                    // borderColor: "#EB321E",
                    backgroundColor: "rgba(11, 49, 143, 0.2)",
                    borderColor: "#0B318F",
                    borderWidth: 2,
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 6,
                    segment: {
                        borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
                        borderDash: ctx => skipped(ctx, [6, 6]),
                    }
                },
            ]
        },
        options: {
            elements: {
                point: {
                    radius: 0
                }
            },
            showTooltips: false,
            responsive: true,
            lineTension: 1,
            scales: {
                yAxes: [
                    {
            ticks: {
                autoSkip: true,
                  maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0
            },
                    }
                ],
                xAxes: [{
            ticks: {
                autoSkip: true,
                  maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0
            },
          type: 'time',
          time: {
            tooltipFormat: 'LL'
          },

          gridLines: {
            display: false
          }
        }]
            }
        }
    }
    return result
};



export default navChartDataThreeYear;