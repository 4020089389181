<template>
  <div>
    <canvas id="aum-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import aumChartData from "./aum-data";

export default {
  name: "AumPerformanceChart",
  props: {
    idProduct: {
      type: Number,
    },
  },
  data() {
    return {
      aumChartData: aumChartData(this.idProduct),
    };
  },
  async mounted() {
    const ctx = document.getElementById("aum-chart");
    new Chart(ctx, await this.aumChartData);
  },
};
</script>

<style lang="scss" scoped>
canvas {
  height: 100% !important;
  width: 100% !important;
}

.aum-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
    margin-left: 15px;
  }
}

@media only screen and (min-width: 1366px) {
  canvas {
    height: 100% !important;
    width: 100% !important;
  }
}
</style>